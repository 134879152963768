import { useEffect, useState } from "react";

import { useAuth } from "~features/auth";
import {
  CheckoutItemOptions,
  clearCartState,
  openCheckout,
  retrieveCartState,
  saveCartState,
} from "../cart";
import { useChargebee } from "./use-chargebee";
import { useCustomerInfo } from "./use-customer";
import { usePlan } from "./use-plan";

// type CartOutput = ReturnType<typeof addToCart>;
interface CartContext {
  state: CheckoutItemOptions | null;
  /** Add a plan to the cart */
  addToCart: (options?: CheckoutItemOptions) => void; // CartOutput;
  /** Add a plan to the cart and immediately proceed to checkout */
  openCheckout: (options?: CheckoutItemOptions) => void;
  /** Use the current cart state to checkout */
  checkout: () => void;
  /**
   * Save the current cart state to local storage
   * @param {CheckoutItemOptions} [item] The item to save. If not provided, saves the current cart state
   * @param {boolean} [checkout] Whether to proceed to checkout on page reload
   */
  saveCartState: (item?: CheckoutItemOptions, checkout?: boolean) => void;
}

/**
 * This hook wraps Chargebee JS cart and checkout functions into a simple interface
 * It also adds state management and local storage cart persistence
 *
 * @TODO: This can likely be simplified and/or combined with useProvideCheckout into a
 * single hook and possibly renamed to useCheckout.
 */
export function useCart(): CartContext {
  const { register } = useAuth();
  const customerInfo = useCustomerInfo();
  const { appSlug, instance, checkout } = useChargebee();
  const { plan, couponCode, customFields } = usePlan();

  const canCheckOut = !!instance && !!customerInfo;
  const defaultOptions = {
    planId: plan?.id,
    couponCode,
    customFields,
    customer: customerInfo || undefined,
  };

  const [state, setState] = useState<CheckoutItemOptions | null>(null);
  const authRedirect = (item?: CheckoutItemOptions) => {
    saveCartState(item || state, true);
    register();
  };

  // TODO: Global cart and checkout state management

  return {
    state,
    addToCart: (options?: CheckoutItemOptions) => {
      const item = { ...defaultOptions, ...(options || {}) };
      setState(item);
      // return addToCart(item, instance);
    },
    checkout: () => {
      if (!canCheckOut) return authRedirect();
      openCheckout(state, instance, checkout.callbacks, appSlug);
    },
    saveCartState: (item, checkout = false) => {
      saveCartState(item || state, checkout);
    },
    openCheckout: (options?: CheckoutItemOptions) => {
      const item = { ...defaultOptions, ...(options || {}) };
      setState(item);
      if (!canCheckOut) return authRedirect(item);
      openCheckout(item, instance, checkout.callbacks, appSlug);
    },
  };
}

export function useCartAutoCheckout() {
  const { instance } = useChargebee();
  const customerInfo = useCustomerInfo();
  const canAutoCheckOut = !!instance && !!customerInfo;

  const { openCheckout } = useCart();

  useEffect(() => {
    if (!canAutoCheckOut) return;

    function autoRestoreCart() {
      const { item, url, autoCheckout } = retrieveCartState() || {};
      if (!autoCheckout) return;

      // Don't restore a cart if the item JSON is missing
      if (!item) return clearCartState();

      // Don't checkout if the path has changed
      if (new URL(url).pathname !== window.location.pathname) return;
      openCheckout(item);
      clearCartState();
    }

    // Wait a bit before restoring the cart to make the garbage collector gremlins happy
    const timeout = setTimeout(autoRestoreCart, 500);
    return () => clearTimeout(timeout);
  }, [canAutoCheckOut]);
}
