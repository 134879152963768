import type {
  ChargebeeInstance,
  Customer,
  OpenCheckoutOptions,
} from "@chargebee/chargebee-js-types";
import { plans } from "@sciam/chargebee/plans.json";
import type { HostedPage } from "chargebee";
import { fetchWithAuth } from "~features/auth/access-token";
import { defaultAppSlug } from "./environment";

/** Resolve a plan by its Item ID or its default Price ID */
function resolvePlan(planId: string) {
  return plans.find((plan) => plan.id === planId || plan.default_price_id === planId);
}

/**
 * Item options for Chargebee carts/checkouts
 *
 * An "Item" is pretty much always a Plan, and purchasing one creates a Customer Subscription.
 *
 * This is a simplified interpolation of Chargebee's product and cart APIs
 * tweaked for our use case, wherein we only ever have one Item being purchased at once and
 * a max of one coupon being applied.
 */
export interface CheckoutItemOptions {
  /**
   * A Chargebee Plan ID, e.g. `PRINTDIG` or `PRINTDIG_INT_OLD`
   *
   * In the frontend, we also accept a Price ID, e.g. `PRINTDIG_P` or `PRINTDIG_INT_OLD_P` and
   * resolve the Plan ID from that.
   *
   * @see packages/chargebee/plans.json for the full list
   */
  planId: string | null;
  /**
   * Optional billing period for the plan.
   * Only used when a plan can have multiple prices for differing period units
   */
  period?: "year" | "month" | "week" | "day" | "initial";
  /** Optional coupon code or coupon id to apply to the checkout */
  couponCode?: string | null;
  /**
   * Optional custom fields such as campaign params to pass to Chargebee checkout
   *
   * From Chargebee docs:
   * >For example, you can store the ID of the marketing campaign that initiated the user session.
   * >After a successful checkout, when the customer is redirected, you can retrieve the hosted page
   * >ID from the redirect URL's query parameters. Using this ID, you can fetch the hosted page and
   * >perform actions related to the success of the marketing campaign.
   */
  customFields?: Record<string, any> | null;
  /** Optional URL to redirect to after checkout */
  redirectUrl?: string;
  /** Optional URL to redirect to if the user cancels checkout */
  cancelUrl?: string;
  /** Optional, should almost only ever be `1` */
  quantity?: number;
  /**
   * Optional customer information.
   * @TODO: We can probably remove this after testing hosted page api checkouts
   * @FIXME The Customer typedef is incorrect, use snake case (first_name) instead of camel case (firstName)
   */
  customer?: Customer;
}

export function setCart(
  { planId, period, couponCode, customFields, quantity = 1, customer }: CheckoutItemOptions,
  cbInstance: ChargebeeInstance,
) {
  const plan = resolvePlan(planId);
  if (!plan) throw new Error("Plan not found", { cause: planId });
  if (!plan.enabled_for_checkout) throw new Error("Plan not enabled for checkout");

  const price_id = plan.price[period] || plan.default_price_id;
  const cart = cbInstance.getCart();
  const product = cbInstance.initializeProduct(price_id, quantity);

  if (couponCode) product.addCoupon(couponCode);
  if (customFields) product.setCustomData(customFields);

  if (customer) cart.setCustomer(customer);
  cart.replaceProduct(product);

  return {
    plan,
    product,
    cart,
  };
}

const LS_CB_CART_KEY = "sa_chargebee_cart";
export function saveCartState(item: CheckoutItemOptions, autoCheckout = false) {
  localStorage.setItem(
    LS_CB_CART_KEY,
    JSON.stringify({
      autoCheckout,
      item,
      url: window.location.href,
      timestamp: Date.now(),
    }),
  );
}

export function retrieveCartState() {
  const cartState = localStorage.getItem(LS_CB_CART_KEY);
  if (!cartState) return;

  let payload: {
    item: CheckoutItemOptions;
    url: string;
    autoCheckout: boolean | undefined;
  };
  try {
    payload = JSON.parse(cartState);
  } catch (error) {
    return console.error("[chargebee] Failed to parse cart state", error);
  }

  return payload;
}

export function clearCartState() {
  localStorage.removeItem(LS_CB_CART_KEY);
}

export type CheckoutCallbacks = Partial<
  Pick<OpenCheckoutOptions, "close" | "error" | "loaded" | "step" | "success">
>;

/**
 * Open the Chargebee Checkout modal
 *
 * ## You should probably not be calling this function directly.
 * Use `useCart().openCheckout()` instead.
 */
export const openCheckout = (
  itemOptions: CheckoutItemOptions,
  cbInstance: ChargebeeInstance,
  callbacks: CheckoutCallbacks = {},
  appSlug = defaultAppSlug,
) =>
  cbInstance.openCheckout({
    // Request a Chargebee hosted page object from our checkout endpoint in Express
    // https://apidocs.chargebee.com/docs/api/hosted_pages?prod_cat_ver=2#hosted_page_attributes
    hostedPage: () => fetchHostedCheckoutPage(appSlug, itemOptions),
    // Chargebee wants us to use enums here but we use the "as" keyword to override that
    // Possible values are "in_app" and "full_page". Setting this to undefined
    // will use whatever we set in our "Checkout & Self-Serve Portal" settings
    layout: undefined as OpenCheckoutOptions["layout"],
    close: logger("close", callbacks.close),
    error: logger("error", callbacks.error),
    loaded: logger("loaded", callbacks.loaded),
    step: logger("step", callbacks.step),
    success: logger("success", callbacks.success),
  });

export const fetchHostedCheckoutPage = async (
  appSlug: string,
  { planId, period, couponCode, customFields, quantity = 1, customer }: CheckoutItemOptions,
  extras?: Record<string, any>,
) => {
  const plan = resolvePlan(planId);
  if (!plan) throw new Error("Plan not found", { cause: planId });
  if (!plan.enabled_for_checkout) throw new Error("Plan not enabled for checkout");

  const price_id = plan.price[period] || plan.default_price_id;

  // Dispatch a custom event to notify trackers that a checkout is starting
  window.dispatchEvent(
    new CustomEvent<CheckoutInfo>("chargebee:checkout-start", {
      detail: {
        // Resolved plan and price ids
        planId: plan.id,
        priceId: price_id,
        // Customer & campaign information
        customer,
        customFields,
        // Optional extras (these will probably be undefined most of the time)
        period,
        couponCode,
        quantity,
      },
    }),
  );

  // Request a Chargebee hosted page object from our checkout endpoint in Express
  let data: HostedPage;
  try {
    const response = await fetchWithAuth("/auth/chargebee/checkout/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        "X-Sciam": "node",
      },
      body: new URLSearchParams({
        cb_app_id: appSlug,
        price_id,
        coupon_id: couponCode || "",
        pass_thru_content: customFields ? JSON.stringify(customFields) : "",
        return_to: extras?.returnTo || "",
        redirect_url: extras?.redirectUrl || "",
        cancel_url: extras?.cancelUrl || "",
      }),
    });

    if (!response.ok) {
      throw new Error(
        `[chargebee] Failed to fetch hosted checkout session: ${response.statusText}`,
      );
    }

    data = (await response.json()) as HostedPage;
  } catch (error) {
    // Dispatch a custom event to notify trackers that a checkout page creation failed
    window.dispatchEvent(new CustomEvent<Error>("chargebee:checkout-error", { detail: error }));
    throw error;
  }

  // Dispatch a custom event to notify trackers that a checkout was created successfully
  window.dispatchEvent(
    new CustomEvent<CheckoutCreatedInfo>("chargebee:checkout-created", {
      detail: {
        hostedPageId: data.id,
        // e.g. "checkout_existing" or "checkout_new"
        type: data.type,
        planId: plan.id,
        priceId: price_id,
      },
    }),
  );
  logger("checkout-created")(data);
  return data;
};

const logger =
  (name, cb?: Function) =>
  (...args) => {
    console.log("[chargebee] openCheckout", name, ...args);
    return cb?.(...args);
  };
